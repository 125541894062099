import React from 'react';

const Headshot = () => {
    return (
        <svg className="paintedHeadshot" width="1120" height="2490" viewBox="0 0 1120 2490" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M414 29.9998L400 61.5559L400 206L414 218L472 232L518 206L556 218L614 218L672 232L708 256L764 318L890 506L924 610L954 750L964 840L944 872L904 982L822 1096L730 1188L730 1284L700 1398L700 1462L672 1584L727.999 1752L689.999 1876L701.999 2006L689.999 2142L671.999 2328L612 2400L671.999 2400L781.999 2364L851.999 2290L971.999 2142L1050 2142L1080 2078L1080 1958L1120 1822L1120 1718L1100 1630L1120 1556L1100 1422L1078 1338L1100 1222L1066 1112L1120 1024L1100 932L1066 840L1076 750L1066 640L1040 538L996 454L996 346L954 258L890 180L754 61.5558L602 -9.90033e-05L484 -0.000150368L414 29.9998Z" fill="#403C49"/>
            <path d="M892 507.999L812 397.999L766 322L719 276L672 230L610 220L560 220L516 209.665L472 230L408 230L376 256L346 322L322 414L268 634L285 745L294 866L322 950L388 1036L572 1174L654 1202L748 1174L812 1106L904 988L938 898L964 848L950 732L926 609.999L892 507.999Z" fill="#f3e4cf"/>
            <path d="M268.001 623.997L328.001 383.997L370.214 265.997L410.5 222.021L402 56L346 68L232 184L192 244L130 488L98 540L86 596L98 678L98.0001 734L79 796L60 858L44 928L60 1000L60 1068L16 1134L8.18159e-06 1218L16.0001 1280L44 1360L44 1400L16 1494L16 1582L44 1690L44 1730L16 1844L28 1904L88 2026L88 2084L61.9999 2152L74 2196L102 2254L194 2346L194 2442L234 2346L292 2346L308 2456L400 2418L444 2320L464 2366L464 2490L506 2408L524 2254L576 2170L542 2070L558 1946L524 1774L558 1730L576 1660L558 1596L512 1558L496 1512L496 1452L524 1360L512 1302L422 1174L392 1046L298.001 869.997L268.001 623.997Z" fill="#403C49"/>
            <path d="M904.453 523.494L866.387 491.603L834.373 491.932L744.229 539.261L767.509 557.18L866.146 513.799L904.453 523.494Z" fill="#403C49"/>
            <path d="M372 576L416 530L474 512H540L600 540L586 561.5L516 540H446L372 576Z" fill="#403C49"/>
            <path d="M520 610L558 632L584 662L536 662L470 662L404 646L470 622L520 610Z" fill="white"/>
            <path d="M917.905 619.509L892 596L832 596L792 619.509L764 652L804 652L862 652L917.905 619.509Z" fill="white"/>
            <path d="M592 956L494.997 939.5L534 968L598 1002L680 1010L748 992L806 939.5L708 956L592 956Z" fill="white"/>
            <line x1="495" y1="937.5" x2="496" y2="937.5" stroke="black"/>
            <path d="M752 926L694 937.5L655 931L579 937.5H486L614 958H704L779 945L813 931L752 926Z" fill="#D23881"/>
            <path d="M812.992 932.5L784 962L740 995L704 1005L680 1011L622 1005L595 1005L570 988L539 971L494.992 940L539 995L590 1037L628 1055L704 1055L740 1047L765 1025L795 981L812.992 932.5Z" fill="#D23881"/>
            <path d="M382.807 1031.46L528 1147L572 1182L656 1203L733 1182L716 1260L722 1290L674 1330L597 1372L559 1372L528 1347L521 1318L494 1265L423 1161L408 1086L382.807 1031.46Z" fill="#f3e4cf"/>
            <ellipse cx="500.5" cy="640" rx="30.5" ry="22" fill="#593B2D"/>
            <ellipse cx="823.5" cy="626.5" rx="25.5" ry="22.5" fill="#593B2D"/>
            <path d="M587.038 653.502L525 621L489 616L440 638L401 648L462 621L517 609L555 629L587.038 653.502Z" fill="#057196"/>
            <path d="M794 617.51L765.9 648.51L798 622L831 605L863 605L919 622L889 597L863 597L831 597L794 617.51Z" fill="#057196"/>
            <path d="M910 625C914.339 623.307 916.358 620.821 920 619" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default Headshot;
